.nav-item {
  a {
    color: white;
    margin-left: 8px;
    margin-right: 8px;
    text-decoration: none;

    &:hover {
      opacity: .65;
    }
  }

  a.active {
    color: #9eeaf9;
  }
}

.navbar {
  .dropdown-item {
    > a {
      text-decoration: none;
      display: flex;
    }
  }
}
