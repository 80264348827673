.province__municipalities {
  display: flex;
  flex-wrap: wrap;
}

.municipality-card {
  margin-top: 8px;
  margin-right: 8px;
  padding-left: 8px;
  padding-right: 8px;
}
