.popover-container {
  position: relative;
  display: inline-flex;
  width: 100%;
}

.popover-trigger {
  width: 100%;
}

.popover-content {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  white-space: nowrap;
  background-color: #FFFFFF;
  box-shadow: 0 4px 15px 0 #00000040;
  z-index: 5;
}
