.real-estates-filter {
  margin-bottom: 24px;
  button + button {
    margin-left: 6px;
  }
}

.real-estate-table-preview-wrapper {
  width: 140px;
  height: 96px;
}

.real-estate-table-preview {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
