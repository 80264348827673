.table {
  td {
    font-size: 14px;
  }
}

.client-details {
  .details-card {
    .card-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
