.leads {
  .table-responsive {
    overflow-x: inherit;
  }

  .form-label {
    font-weight: 500;
  }

  .form-label > i {
    margin-right: 6px;
    position: relative;
    top: 1px;
  }

  .form-label + div {
    font-weight: 300;
  }

  .date {
    font-weight: 300;
    font-size: 14px;
  }

  input[name="city"]:disabled {
    background-color: hsl(0, 0%, 95%);
    color: hsl(0, 0%, 60%);
  }
}
