.re {
  .details-card + .details-card {
    margin-top: 16px;
  }

  .row-card-description + .row-card-description {
    margin-top: 32px;
  }

  .card-key {
    font-size: 14px;
    font-weight: 600;
  }

  .card-value {
    font-size: 14px;
    font-weight: 400;
  }

  .col-extra {
    flex: 0 0 0;
  }

  .previews {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, 190px);

    .alert {
      width: 100%;
    }
  }

  .preview {
    width: 190px;
    height: 150px;
    border: 1px solid ghostwhite;
    border-radius: 6px;
    overflow: hidden;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .icon-close {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 3px;
      right: 3px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      &:before {
        background-color: red;
        color: white;
      }
    }

    &.preview-queue-upload {
      opacity: .75;
    }
  }

  .preview-uploader {
    margin-top: 16px;
  }

  .details-card {
    input[type="file"] {
      display: none;
    }

    label.btn {
      margin-top: 16px;
      max-width: 240px;
    }
  }

  .details-card + button {
    margin-top: 24px;
  }

  .user-extras {
    .badge {
      margin-right: 6px;
      cursor: pointer;
      height: 28px;
      display: inline-flex;
      align-items: center;
      margin-bottom: 6px;

      &:hover {
        opacity: .85;
      }

      &:hover {
        opacity: .75;
      }
    }

    i {
      margin-right: 4px;

      &:before {
        font-size: 16px;
      }
    }
  }

  label[for="presentationAddress"],
  label[for="realEstateMarketingName"],
  label[for="realEstateMarketingNameES"],
  label[for="realEstateMarketingDescription"],
  label[for="realEstateMarketingDescriptionES"],
  label[for="longitude"],
  label[for="latitude"],
  label[for="clientPrice"],
  label[for="catalogPrice"],
  label[for="presentationManualAddress"],
  label[for="finalPrice"] {
    font-weight: 500;

    + input.form-control:not(:disabled) {
      background-color: #F0FFF0;
    }
  }

  .streets-combobox__trigger {
    input[type="text"][name] {
      background-color: #F0FFF0;
    }
  }
  .municipality-combobox {
    .municipality-combobox__trigger {
      .field-municipality {
        background-color: #F0FFF0;
      }
    }
  }

  [name="realEstateMarketingDescription"],
  [name="realEstateMarketingDescriptionES"] {
    background-color: #F0FFF0;
  }

  #realEstateMarketingDescription[contenteditable="false"] {
    background-color: var(--bs-secondary-bg);;
  }

  .user-extras.user-extras-disabled {
    .badge:hover, .badge:active {
      opacity: 1;
      cursor: default;
    }
  }

  .best-offer {
    display: flex;
    align-items: flex-end;

    input {
      top: 8px;
      position: relative;
      cursor: pointer;
    }

    label {
      font-weight: 500;
      cursor: pointer;
    }
  }

  .card-header-details {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: rgba(248, 248, 248, 1);
  }

  #realEstateMarketingDescription {
    min-height: 320px;
  }

  .save-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
  }
  .save-progress {
    position: fixed;
    top: 24px;
    right: 24px;
    padding: 16px;
    background-color: #20c997;
    font-weight: bold;
    color: #ffffff;
    z-index: 9;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
  }

  .save-progress__item {
    padding-left: 22px;
    position: relative;
    display: flex;
    align-items: center;

    .spinner-border {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }

    .spinner-border {
      top: 2px;
    }
  }

  .validation-error {
    position: relative;
    white-space: pre-wrap;

    input {
      border-color: #e8585a;
    }
  }
  .validation-error:after {
    font-size: 11px;
    line-height: 16px;
    bottom: -16px;
    color: #e8585a;
    content: attr(data-error);
    display: inline-block;
    text-transform: uppercase;
    z-index: 10;
  }
}
