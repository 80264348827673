.field-phone-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;

  &.field-phone-wrapper-2 {
    .field-phone-dropdown {
      left: 31px;
    }
  }

  &.field-phone-wrapper-3 {
    .field-phone-dropdown {
      left: 18px;
    }
  }

  &.field-phone-wrapper-4 {
    .field-phone-dropdown {
      left: 8px;
    }
  }

  &.field-phone-wrapper-5 {
    .field-phone-dropdown {
      left: 2px;
    }
  }
}

.field-phone-dropdown-wrapper {
  overflow: hidden;
  position: absolute;
  width: 69px;
  left: 0;
  bottom: 0;
  top: 0;

  .icon-arrow-down {
    right: 6px;
    bottom: 0;
    top: 0;
    margin: auto;
    position: absolute;
    width: 16px;
    height: 16px;
    pointer-events: none;
  }
}

.field-phone-dropdown {
  border-color: transparent;
  background-color: transparent;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  font-size: 14px;

  &:not(&:disabled) {
    cursor: pointer;
  }

  &:focus,
  &:active {
    border-color: transparent;
    background-color: transparent;
    outline: none;
  }
}

.field-phone {
  $field-background: grey;
  $text-base-color: black;

  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  min-height: calc(1.5em + 0.5rem + var(--bs-border-width)* 2);
  font-size: 0.875rem;
  border-radius: var(--bs-border-radius-sm);

  padding: 0 24px 0 69px;
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  transition: all .3s;
}
