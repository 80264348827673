@import "fonts";
@import "helpers";

.table.table-hover {
  tr:hover {
    cursor: pointer;
  }

  td {
    font-size: 14px;
  }
}

.form-label {
  font-weight: 500;
}

input[type="text"]::placeholder {
  color: #cecece !important;
}

.details-card + .details-card {
  margin-top: 16px;
}
