.form-label {
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}

// Модификаторы отступов, блоков
@each $num in (0, 2, 4, 8, 16, 24, 32, 48, 80, 150) {
  @each $type in (padding, margin) {
    .#{$type}-#{$num} {
      #{$type}: #{$num}px;
    }

    @each $side in (left, right, top, bottom) {
      .#{str-slice($type, 0, 1)}#{str-slice($side, 0, 1)}-#{$num} {
        #{$type}-#{$side}: #{$num}px;
      }
    }
  }
}

// Модификаторы текста
@each $val in (left, center, right) {
  .ta-#{'' + $val} {
    text-align: $val;
  }
}
