.municipality-combobox {
  $bg: #FAF9F6;

  .municipality-combobox__trigger {
    display: flex;
    position: relative;
  }

  .field-municipality {
    $field-background: grey;
    $text-base-color: black;

    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    min-height: calc(1.5em + 0.5rem + var(--bs-border-width)* 2);
    font-size: 0.875rem;
    border-radius: var(--bs-border-radius-sm);
    padding: 0.25rem 2rem 0.25rem 0.5rem;
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
    transition: all .3s;
  }

  .field-text {
    padding-left: 50px;
    padding-right: 42px;
  }

  .field-prefix, .field-postfix {
    top: 0;
    bottom: 0;
    margin: auto;
    display: inline-flex;
    align-items: center;
  }

  .field-prefix {
    position: absolute;
    left: 20px;

    &:before {
      font-size: 24px;
      color: #311E0D;
    }
  }

  .field-postfix {
    position: absolute;
    right: 0;

    &:before {
      font-size: 20px;
      color: #B8AC95;
    }
  }

  $close: #1C1B1F;

  .icon-close {
    width: 24px;
    height: 24px;
    line-height: 24px;
    cursor: pointer;

    &:before {
      font-size: 18px;
      color: lighten($close, 55%);
    }

    &:hover {
      &:before {
        color: lighten($close, 25%);
      }
    }

    &:active {
      &:before {
        color: lighten($close, 35%);
      }
    }
  }

  .popover-content {
    z-index: 4;
    max-height: 156px;
    overflow: auto;
    margin-top: 2px;

    > button {
      font-size: 15px;
      line-height: 18px;
      font-weight: 300;
      padding: 0.25rem 0.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      width: 100%;
      background-color: transparent;
      border-color: transparent;
      text-align: left;
      display: flex;

      &:focus, &:active {
        outline: none;
        border-color: transparent;
      }

      &:hover {
        background-color: darken($bg, 4%);
      }

      &:active {
        background-color: darken($bg, 8%);
      }
    }

    .no-match {
      font-size: 15px;
      line-height: 18px;
      font-weight: 300;
      padding: 12px 26px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      background-color: transparent;
      border-color: transparent;
      text-align: left;
      cursor: default;
      &:hover {
        background-color: transparent;
      }
      &:active {
        background-color: transparent;
      }

      &.no-match {
        color: #dedede;
      }
    }
  }
}
