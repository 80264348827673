.navbar {
  > div {
    max-width: 1280px;
    margin: 0 auto;
  }
}

.content {
  max-width: 1280px;
  margin: 24px auto;
}
